<template>
  <span :id="id" class="hs-field-in-step">
    <el-date-picker v-model="localValue" type="datetime" :placeholder="placeholder" />
  </span>
</template>

<script>
import FieldBaseMixin from './_field-base-mixin.js'

export default {
  mixins: [FieldBaseMixin],
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    localValue: {
      get() {
        return this.value
      },
      set(val) {
        this.setValue(val)
      },
    },
  },
  methods: {
    setValue(val) {
      this.$emit('set-value', val)
    },
  },
}
</script>

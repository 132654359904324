// import Vue from 'vue'
// export const notificationBus = new Vue({
//   methods: {
//     notify(obj) {
//       this.$emit('notify', obj)
//     },
//   },
// })

// EventBus (https://alligator.io/vuejs/global-event-bus/)
// import { notificationBus } from '@/notification-bus.js'


// import { reactive } from 'vue'

// export const notificationBus = reactive({
//   listeners: [],

//   notify(obj) {
//     this.listeners.forEach((callback) => callback(obj))
//   },

//   on(callback) {
//     this.listeners.push(callback)
//   },

//   off(callback) {
//     this.listeners = this.listeners.filter(listener => listener !== callback)
//   },
// })


import mitt from 'mitt'

const emitter = mitt()

const LOGGER_ENABLED = false

const logger = (action, event, payload) => {
  if (!LOGGER_ENABLED) return
  console.log(`[notificationBus] ${action}:`, { event, payload })
}

export const notificationBus = {
  notify(payload) {
    logger('emit', 'notify', payload)
    emitter.emit('notify', payload)
  },
  on(event, handler) {
    logger('on', event, handler)
    emitter.on(event, handler)
  },
  off(event, handler) {
    logger('off', event, handler)
    emitter.off(event, handler)
  },
}

<template>
  <g>
    <path d="M31.4681 8.97127C32.8068 6.86757 36.0633 7.81588 36.0633 10.3094L36.0632 26.6745C36.0632 28.051 34.9473 29.1669 33.5708 29.1669H23.1566C21.1899 29.1669 19.998 26.9956 21.0539 25.3364L31.4681 8.97127Z" fill="currentColor" /><path d="M36.5044 41.0545C35.1657 43.1582 31.9092 42.2099 31.9092 39.7163L31.9093 23.3512C31.9093 21.9747 33.0252 20.8588 34.4018 20.8588H44.8159C46.7827 20.8588 47.9746 23.0301 46.9187 24.6894L36.5044 41.0545Z" fill="currentColor" />
    <rect
      x="6.98462"
      y="10.0583"
      width="16.6162"
      height="6.64646"
      rx="3.32323"
      fill="currentColor"
    />
    <rect
      x="6.98462"
      y="34.9824"
      width="16.6162"
      height="6.64647"
      rx="3.32323"
      fill="currentColor"
    />
    <rect
      x="2"
      y="22.5205"
      width="15.7853"
      height="6.64646"
      rx="3.32323"
      fill="currentColor"
    />
  </g>
</template>

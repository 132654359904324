import { notificationBus } from '../../../notification-bus/notification-bus.js'
import idb from '../../idb.js'

export default {
  namespaced: true,
  state: () => ({
    scripts: [],
    openedScripts: [],
  }),

  /**
   * ---------------------------------------------------------------------------------------------------------------------------
   *  MUTATIONS
   * ---------------------------------------------------------------------------------------------------------------------------
   */
  mutations: {
    GET_SCRIPTS(state, payload) {
      const { remoteScripts, localScripts } = payload
      state.scripts = remoteScripts
      state.openedScripts = localScripts
    },
    GET_OPENED_SCRIPTS(state, payload) {
      state.openedScripts = payload
    },
    CLEAR_SCRIPTS(state) {
      state.scripts = []
      state.openedScripts = []
    },
    CREATED_SCRIPT(state, payload) {
      const { id } = payload
      if (!state.scripts.some((e) => e.id === id) && payload.name) {
        state.scripts.push(payload)
        notificationBus.notify({ title: 'HyperScript', message: 'socketCallbackMessage.createdSuccess', type: 'success' })
      }
    },
    UPDATE_SCRIPT(state, payload) {
      const { script } = payload
      const indexInList = state.scripts.findIndex((e) => e.id === script.id)
      const indexInSaved = state.openedScripts.findIndex((e) => e.id === script.id)
      if (indexInList !== -1) {
        state.scripts.splice(indexInList, 1, script)
        notificationBus.notify({
          title: 'HyperScript',
          message: { t: 'socketCallbackMessage.updatedSuccess', v: { name: script.name } },
          type: 'success',
        })
      }
      if (indexInSaved !== -1) {
        script._last_view = state.openedScripts[indexInSaved]._last_view
        state.openedScripts.splice(indexInSaved, 1, script)
        const clone = JSON.parse(JSON.stringify(script))
        idb.setOpenedScript(clone)
      }
    },
    REMOVE_SCRIPT(state, payload) {
      const { id } = payload
      const indexInList = state.scripts.findIndex((e) => e.id === id)
      const name = String(state.scripts[indexInList].name)
      const indexInSaved = state.openedScripts.findIndex((e) => e.id === id)
      if (indexInList !== -1) {
        state.scripts.splice(indexInList, 1)
        notificationBus.notify({
          title: 'HyperScript',
          message: { t: 'socketCallbackMessage.removedSuccess', v: { name } },
          type: 'success',
        })
      }
      if (indexInSaved !== -1) {
        state.openedScripts.splice(indexInSaved, 1)
      }
    },
    UPDATE_LAST_VIEW(state, payload) {
      const { id, _last_view } = payload
      const find = state.openedScripts.find((script) => script.id === id)
      find._last_view = _last_view
    },
    ADD_OPENED_SCRIPT(state, payload) {
      state.openedScripts.push(payload)
    },
  },

  /**
   * ---------------------------------------------------------------------------------------------------------------------------
   *  ACTIONS
   * ---------------------------------------------------------------------------------------------------------------------------
   */
  actions: {
    GET_SCRIPTS({ commit }, payload) {
      commit('GET_SCRIPTS', payload)
    },
    GET_OPENED_SCRIPTS({ commit }, payload) {
      commit('GET_OPENED_SCRIPTS', payload)
    },
    CLEAR_SCRIPTS({ commit }) {
      commit('CLEAR_SCRIPTS')
    },
    CREATED_SCRIPT({ commit }, payload) {
      commit('CREATED_SCRIPT', payload)
    },
    UPDATE_SCRIPT({ commit }, payload) {
      commit('UPDATE_SCRIPT', payload)
    },
    REMOVE_SCRIPT({ commit }, payload) {
      commit('REMOVE_SCRIPT', payload)
    },
  },
}

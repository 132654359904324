<template>
  <div>
    <div v-if="scriptWaitingToUpdate || scriptWaitingToRemove" class="lg:hidden mb-8">
      <p v-if="scriptWaitingToUpdate" class="inline lg:hidden text-sm">
        <i class="el-icon-info text-hs-secondary mr-1" />
        {{ $t('pass.futureScriptStatus.updated') }}.
      </p>
      <p v-if="scriptWaitingToRemove" class="inline lg:hidden text-sm">
        <i class="el-icon-info text-hs-secondary mr-1" />
        {{ $t('pass.futureScriptStatus.deleted') }}.
      </p>
    </div>

    <!-- Step text -->
    <pass-text v-if="currentStepData.step && currentStepData.step.text" :uid="uid" :step="currentStepData.step" />
    <!-- /Step text -->
    <!-- Answer options -->
    <div class="mt-5">
      <pass-variant-picker
        v-for="(v, idx) in currentStepData.variants"
        :key="idx"
        :class="[`${v.status}`, { 'is-clicked': isClickedButtonId === v.id }]"
        :disabled="Boolean(isClickedButtonId)"
        @select="toggleVariant(v.target, v.id)"
      >
        {{ v.condition }}
      </pass-variant-picker>
    </div>
    <!-- /Answer options -->
    <!-- If this is the end of a conversation show the button -->
    <div>
      <hs-main-button v-if="isLastVariant" variant="primary" class="lg:w-72" @click="toggleDone">
        {{ $t('pass.conversationCompleted') }}
      </hs-main-button>
    </div>
    <!-- /If this is the end of a conversation show the button -->
    <!-- Appropriate response is missing -->
    <div class="mt-5">
      <button
        v-if="currentStepData.variants.length > 0 && !answerWasAdded"
        class="fixed bottom-44 left-0 lg:relative lg:bottom-auto lg:left-auto px-4 py-2.5 w-full lg:w-72 block lg:inline-block font-bold lg:rounded-xl text-base bg-white border-t-2 lg:border-b-2 lg:border-r-2 lg:border-l-2 border-hs-info focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-hs-secondary hover:text-hs-secondary transition-colors"
        @click="toggleNoAnswer"
      >
        {{ $t('pass.appropriateResponseIsMissing.name') }}
      </button>
      <hs-textarea-material
        v-if="checkNoAnswerFlag(currentStepId, uid)"
        :id="currentStepId"
        :value="getNoAnswerValue(currentStepId, uid)"
        :placeholder="$t('pass.appropriateResponseIsMissing.name')"
        @set-value="setNoAnswerValue($event, currentStepId, uid)"
      />
      <p v-if="checkNoAnswerFlag(currentStepId, uid)" class="text-xs mb-5 mt-2">
        <i class="el-icon-info mr-1 text-hs-secondary relative" style="top: 1px" />
        {{ $t('pass.appropriateResponseIsMissing.answerInTheEnd') }}
      </p>
    </div>
    <!-- /Appropriate response is missing -->
  </div>
</template>

<script>
import HsMainButton from '@/core/design-system/buttons/hs-main-button.vue'
import PassVariantPicker from './pass-variant-picker.vue'
import HsTextareaMaterial from '@/core/design-system/form-elements/hs-textarea-material.vue'
import PassText from './pass-text.vue'

export default {
  components: {
    HsMainButton,
    PassVariantPicker,
    HsTextareaMaterial,
    PassText,
  },
  data: () => ({
    isClickedButtonId: null,
  }),
  computed: {
    uid() {
      return this.$parent.currentScript._uid
    },
    currentStepData() {
      return this.$parent.currentStepData
    },
    isLastVariant() {
      return this.$parent.isLastVariant
    },
    currentStepId() {
      return this.currentStepData?.step?.id || null
    },
    answerWasAdded() {
      return this.$store.getters.pass?.passStack[this.uid]?.unexpected.some((e) => e.id === this.currentStepId)
    },
    scriptWaitingToUpdate() {
      return this.$parent.scriptWaitingToUpdate
    },
    scriptWaitingToRemove() {
      return this.$parent.scriptWaitingToRemove
    },
  },
  methods: {
    toggleVariant(eventId, buttonId) {
      /**
       * eventId - step id
       * buttonId - link id
       */
      this.isClickedButtonId = buttonId
      setTimeout(() => {
        this.isClickedButtonId = null
        this.$parent.send(eventId, buttonId)
      }, 500)
    },
    toggleDone() {
      this.$parent.done()
    },
    checkNoAnswerFlag(stepId, _uid) {
      return this.$parent.checkNoAnswerFlag(stepId, _uid)
    },
    getNoAnswerValue(stepId, _uid) {
      return this.$parent.getNoAnswerValue(stepId, _uid)
    },
    setNoAnswerValue(value, stepId, _uid) {
      this.$parent.setNoAnswerValue(value, stepId, _uid)
    },
    toggleNoAnswer() {
      this.$parent.toggleNoAnswer()
    },
  },
}
</script>

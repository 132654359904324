<template>
  <div class="pass-variant cursor-pointer" :class="{ 'is-disabled': disabled }" @click.stop="toggleClick">
    <button class="font-medium focus:outline-none">
      <slot />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleClick() {
      if (this.disabled === true) {
        return
      }
      this.$emit('select')
    },
  },
}
</script>

import { defineComponent, h } from 'vue'

const DynamicTag = defineComponent({
  name: 'DynamicTag',
  props: {
    tag: {
      type: String,
      default: 'p',
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { slots }) {
    return () => {
      const customClass = []
      const customStyle = []
      if (props.item['@c']) {
        customClass.push(props.item['@c'])
      }
      if (props.tag === 'img') {
        customClass.push('dynamic-media')
      }
      if (props.tag === 'a') {
        customStyle.push('color: blue; text-decoration: underline;')
      }
      customClass.push('mb-2')

      return h(
        props.tag,
        {
          style: customStyle.join(' '),
          class: customClass.join(' '),
          src: props.item['@s'],
          alt: props.item['@a'],
          href: props.item['@h'],
          target: props.item.target,
        },
        slots.default ? slots.default() : null,
      )
    }
  },
})

export default DynamicTag

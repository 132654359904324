<template>
  <button
    ref="tab"
    class="h-full px-5 focus:outline-none"
    style="transition: color .3s;"
    @click="toggleTab"
  >
    <slot />
  </button>
</template>


<script>
import { nextTick } from 'vue'
export default {
    methods: {
    toggleTab() {
      nextTick(() => {
        const payload = {
          indicator_width: this.$refs?.tab?.offsetWidth || 72,
          indicator_pos: this.$refs?.tab?.offsetLeft || 0,
        }
        this.$emit('toggle-tab', payload)
      })
    },
  },
}
</script>

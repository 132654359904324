// import Vue from 'vue'
// export const eventBus = new Vue({
//   methods: {
//     interceptorCallback(obj, callback) {
//       this.$emit('on-callback', obj, callback)
//     },
//   },
// })



// import { reactive } from 'vue'

// const eventBus = reactive({
//   callbacks: [],
//   addListener(callback) {
//     this.callbacks.push(callback)
//   },
//   removeListener(callback) {
//     this.callbacks = this.callbacks.filter(cb => cb !== callback)
//   },
//   emit(event, ...args) {
//     this.callbacks.forEach(callback => callback(event, ...args))
//   },
// })

// export default eventBus

import mitt from 'mitt'

export const eventBus = mitt()

const logEvent = (eventName, ...args) => {
  console.log(`Event: ${eventName}`, ...args)
}

const originalEmit = eventBus.emit
eventBus.emit = (eventName, ...args) => {
  logEvent(eventName, ...args)
  originalEmit.call(eventBus, eventName, ...args)
}

export const interceptorCallback = (obj, callback) => {
  eventBus.emit('on-callback', obj, callback)
}

eventBus.on('on-callback', (obj, callback) => {
  console.log('Callback received:', obj, callback)
})


// EventBus (https://alligator.io/vuejs/global-event-bus/)
// import { eventBus } from '@/notification-bus.js'

<template>
  <transition name="fade-msg">
    <div v-if="value" class="success-save-msg">
      <!-- eslint-disable -->
      <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M31.4851 14.4879C32.1716 15.1385 32.1716 16.1932 31.4851 16.8438L19.7657 27.9496C19.0813 28.5981 17.9726 28.6004 17.2852 27.9549L11.5205 22.5408C10.8309 21.8932 10.8259 20.8384 11.5093 20.185C12.1927 19.5315 13.3058 19.5268 13.9954 20.1744L18.5171 24.421L28.999 14.4879C29.6856 13.8374 30.7986 13.8374 31.4851 14.4879Z"
          fill="white"
        />
        <circle cx="21" cy="21" r="19.5" stroke="white" stroke-width="3" />
      </svg>
      <!-- eslint-enable -->
      <p><slot /></p>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    value: Boolean,
  },
}
</script>

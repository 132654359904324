<script>
import {messagingBus} from '@/core/infrastructure/messaging/messaging'
import {authApi} from '@/core/application/components/auth/utils'

export default {
  name: 'WdeEntryPoint',
  data() {
    return {
      externalUser: null,
      apiKey: null,
    }
  },
  computed: {
    isAuthorized() {
      return this.$store.getters.auth.isAuthorized || false
    },
  },
  created() {
    this.externalUser = this.$route.query.user
    this.apiKey = this.$route.query.api_key

    messagingBus.on('runScript', arg => {
      if(this.isAuthorized) {
        this.$router.push({name: 'wdePass', params: {id: arg.id}})
      }
    })
  },
  async mounted() {
    if(!this.isAuthorized) {
      // attempt to exchange external user and apikey to a hs jwt_token
      try {
        await authApi.post(`${process.env.VUE_APP_IFRAME_API}/api/user/authenticate`, {
          externalUser: this.$route.query.user,
          apiKey: this.$route.query.api_key,
        }, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
        })
        await this.$store.dispatch('app/TOGGLE_LOGIN_SIDEBAR', false)
        setTimeout(() => {
          this.$root.$emit('getUserData')
        }, 1000)
      } catch (e) {
        console.error('WDE AUTHENTICATION error', e)
        await this.$router.push({name: 'wdeLogin', query: {user: this.$route.query.user, api_key: this.$route.query.api_key}})
      }
    }
  },
}
</script>

<template>
  <div>
    <hyperscript-pass-core integration-type="wde" />
  </div>
</template>

<style scoped>

</style>

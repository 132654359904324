// // vue (https://vuejs.org/)
// import Vue from 'vue'
// import App from './App.vue'
// // vue-router (https://router.vuejs.org/ru/)
// import router from './router'
// // vuex (https://vuex.vuejs.org/ru/)
// import store from './store'

// /**
//  * Plugins
//  */
// // vue-i18n (https://github.com/kazupon/vue-i18n)
// import i18n from './i18n'

// /**
//  * Config
//  */
// Vue.config.productionTip = false

// import core from '@/core'
// Vue.use(core)

// new Vue({
//   router,
//   store,
//   i18n,
//   render: h => h(App),
// }).$mount('#app')

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { notificationBus } from './core/infrastructure/notification-bus/notification-bus'
import hsPass from './core'
import i18n from './i18n'
import ElementPlus from 'element-plus'
import ru from 'element-plus/es/locale/lang/ru'
import DynamicTag from './core/pass/components/pass-elements/dynamic-tag'
// import { configureCompat } from 'vue'

/**
 * Styles
 */

import 'element-plus/dist/index.css'

// configureCompat({
//   INSTANCE_ATTRS_CLASS_STYLE: false,
//   ATTR_FALSE_VALUE: false,
//   // ATTR_ENUMERATED_COERCION: false,
// })

const app = createApp(App)
app.config.globalProperties.$notificationBus = notificationBus
app.component('dynamic-tag', DynamicTag)
app.use(router)
app.use(store)
app.use(i18n)
app.use(hsPass)

app.use(ElementPlus, {
  locale: ru,
})

app.mount('#app')

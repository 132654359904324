<template>
  <div>
    <div v-if="isEmpty" class="opacity-50">
      <p class="font-normal text-sm">
        {{ $t('pass.notes.empty') }}..
      </p>
    </div>
    <div v-else>
      <div v-if="currentNote === null">
        <hs-input-search
          id="notesSearch"
          :value="searchValue"
          :placeholder="$t('search')"
          class="mb-2 lg:mb-5"
          @set-value="setValue($event)"
        />
        <div
          v-for="item in notesWithCategory"
          :key="item.category_id"
          :class="{'notes-item': item.category_id !== 0 }"
        >
          <div v-if="item.category_id === 0">
            <div
              v-for="i in item.items"
              :key="i.id"
              class="qt-item"
            >
              <p
                class="inline font-normal cursor-pointer text-hs-primary text-base select-none"
                @click="setCurrentNote(i)"
              >
                {{ i.title }}
              </p>
            </div>
          </div>

          <vs-collapse v-else>
            <vs-collapse-item
              :open="thisCategoryIsOpen(item)"
              :on-callback="() => { toggleCategory(item) }"
            >
            <template v-slot:header>
              <span
                slot="header"
                class="select-none font-medium text-base"
              >
                {{ item.category }}
              </span>
            </template>
              <div
                v-for="i in item.items"
                :key="i.id"
                class="qt-item"
              >
                <p
                  class="inline-block font-normal cursor-pointer text-hs-primary text-base pl-6 select-none"
                  @click="setCurrentNote(i)"
                >
                  {{ i.title }}
                </p>
              </div>
            </vs-collapse-item>
          </vs-collapse>
        </div>
      </div>
      <div
        v-else
        class="relative -top-2 lg:top-0"
      >
        <h3 class="hidden lg:block relative pb-6 font-bold pr-12">
          {{ currentNote.title }}
          <button
            class="absolute right-0 cursor-pointer focus:outline-none"
            style="top: calc(50% - 12px); transform: translateY(-50%);"
            @click="setCurrentNote(null)"
          >
            <icon-base :icon-name="$t('close')">
              <icon-x />
            </icon-base>
          </button>
        </h3>
        <!-- eslint-disable -->
        <div v-html="currentNote.content" />
        <!-- eslint-enable -->
      </div>
    </div>
  </div>
</template>


<script>
import IconX from '@/core/design-system/icon-base/icons/x.vue'
import HsInputSearch from '@/core/design-system/form-elements/hs-input-search.vue'
import VsCollapse from '@/core/components/vs-collapse/vs-collapse.vue'
import VsCollapseItem from '@/core/components/vs-collapse/vs-collapse-item.vue'

export default {
  components: {
    IconX,
    HsInputSearch,
    VsCollapse,
    VsCollapseItem,
  },
  data: () => ({
    searchValue: '',
  }),
  props:{
    currentViewNotes: {
      type: [Object, Array],
      default: () => ({}),
    },
    currentNote: {
      type: Object,
      default: null,
    },
    openedNoteCategories: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isEmpty() {
      return Object.values(this.currentViewNotes).length === 0
    },
    currentViewNotesWithSearch() {
      const fixNullValue = Object.values(this.currentViewNotes).map(note => ({...note, priority: note.priority ? note.priority : 0}))
      const objToArr = fixNullValue.filter(e =>
        e.title.toLowerCase().includes(this.searchValue.toLowerCase()) ||
        e.content.toLowerCase().includes(this.searchValue.toLowerCase()),
      )
      return objToArr.sort((a, b) =>  a.priority - b.priority)
    },
    notesWithCategory() {
      const categories = this.currentViewNotesWithSearch.map(e => ({
        ...e,
        category_id: e.category_id === null ? 0 : e.category_id,
        category: e.category_id === null ? 'without category' : e.category,
      }))
      let uniqCategories = {}
      categories.forEach(el1 => {
        let someOne = Object.keys(uniqCategories).some(el2 => parseInt(el2, 10) === el1.category_id)
        if (!someOne) {
          const cat = {}
          cat.category_id = el1.category_id
          cat.category = el1.category
          cat.items = []
          cat.items.push(el1)
          uniqCategories[el1.category_id] = cat
        } else {
          uniqCategories[el1.category_id].items.push(el1)
        }
      })
      const sorted = Object.values(uniqCategories).map(e => ({
        ...e,
        priority: e.category_id === 0 ? 0 : e.items[0].priority,
      })).sort((a, b) =>  a.priority - b.priority)
      return sorted
    },
  },
  methods: {
    setValue(val) {
      this.searchValue = val
    },
    setCurrentNote(note) {
      this.$emit('set-current-note', note)
    },
    toggleCategory({ category_id }){
      this.$emit('toggle-note-category', category_id)
    },
    thisCategoryIsOpen({ category_id }) {
      return this.openedNoteCategories.some(el => el === category_id)
    },
  },
}
</script>

// import Vue from 'vue'

// export const messagingBus = new Vue({
//   created() {
//     window.addEventListener(
//       'message',
//       (event) => {
//         switch (event.data.method) {
//           case 'hs:showScriptList':
//             this.showScriptList(event.data.arg)
//             break
//           case 'hs:showLoginForm':
//             this.showLoginForm(event.data.arg)
//             break
//           case 'hs:runScript':
//             this.runScript(event.data.arg)
//             break
//           case 'hs:setFieldValue':
//             this.setFieldValue(event.data.arg)
//             break
//           case 'hs:setFieldVisibility':
//             this.setFieldVisibility(event.data.arg)
//             break
//           case 'hs:setFieldOptions':
//             this.setFieldOptions(event.data.arg)
//             break
//           case 'hs:setFieldAutocomplete':
//             this.setFieldAutocomplete(event.data.arg)
//             break
//         }
//       },
//       false,
//     )
//   },
//   methods: {
//     showScriptList(arg) {
//       console.debug('showScriptList', arg)
//       this.$emit('showScriptList', arg)
//     },
//     showLoginForm(arg) {
//       console.debug('showLoginForm', arg)
//       this.$emit('showLoginForm', arg)
//     },
//     runScript(arg) {
//       console.debug('runScript', arg)
//       this.$emit('runScript', arg)
//     },
//     setFieldValue(arg) {
//       console.debug('setFieldValue', arg)
//       this.$emit('setFieldValue', arg)
//     },
//     setFieldVisibility(arg) {
//       console.debug('setFieldVisibility', arg)
//       this.$emit('setFieldVisibility', arg)
//     },
//     setFieldOptions(arg) {
//       console.debug('setFieldOptions', arg)
//       this.$emit('setFieldOptions', arg)
//     },
//     setFieldAutocomplete(arg) {
//       console.debug('setFieldAutocomplete', arg)
//       this.$emit('setFieldAutocomplete', arg)
//     },
//   },
// })


// export const onReady = () => {
//   window.parent.postMessage({event: 'hs:onReady', arg: {ready: true}}, '*')
// }
// export const onSignedIn = (data) => {
//   window.parent.postMessage({event: 'hs:onSignedIn', arg: data}, '*')
// }
// export const onScriptStart = (data) => {
//   window.parent.postMessage({event: 'hs:onScriptStart', arg: data}, '*')
// }
// export const onScriptFinish = (data) => {
//   window.parent.postMessage({event: 'hs:onScriptFinish', arg: data}, '*')
// }
// export const onStepPassed = (data) => {
//   window.parent.postMessage({event: 'hs:onStepPassed', arg: data}, '*')
// }
// export const onFieldChange = (data) => {
//   window.parent.postMessage({event: 'hs:onFieldChange', arg: data}, '*')
// }


import mitt from 'mitt'

export const messagingBus = mitt()

window.addEventListener(
  'message',
  (event) => {
    switch (event.data.method) {
      case 'hs:showScriptList':
        messagingBus.emit('showScriptList', event.data.arg)
        break
      case 'hs:showLoginForm':
        messagingBus.emit('showLoginForm', event.data.arg)
        break
      case 'hs:runScript':
        messagingBus.emit('runScript', event.data.arg)
        break
      case 'hs:setFieldValue':
        messagingBus.emit('setFieldValue', event.data.arg)
        break
      case 'hs:setFieldVisibility':
        messagingBus.emit('setFieldVisibility', event.data.arg)
        break
      case 'hs:setFieldOptions':
        messagingBus.emit('setFieldOptions', event.data.arg)
        break
      case 'hs:setFieldAutocomplete':
        messagingBus.emit('setFieldAutocomplete', event.data.arg)
        break
    }
  },
  false,
)

export const onReady = () => {
  window.parent.postMessage({ event: 'hs:onReady', arg: { ready: true } }, '*')
}

export const onSignedIn = (data) => {
  window.parent.postMessage({ event: 'hs:onSignedIn', arg: JSON.parse(JSON.stringify(data)) }, '*')
}

export const onScriptStart = (data) => {
  window.parent.postMessage({ event: 'hs:onScriptStart', arg: JSON.parse(JSON.stringify(data))}, '*')
}

export const onScriptFinish = (data) => {
  window.parent.postMessage({ event: 'hs:onScriptFinish', arg: JSON.parse(JSON.stringify(data))}, '*')
}

export const onStepPassed = (data) => {
  window.parent.postMessage({ event: 'hs:onStepPassed', arg: JSON.parse(JSON.stringify(data)) }, '*')
}

export const onFieldChange = (data) => {
  window.parent.postMessage({ event: 'hs:onFieldChange', arg: JSON.parse(JSON.stringify(data)) }, '*')
}

// import Vue from 'vue'
import { timeDiffInSeconds } from '@/core/infrastructure/helpers/helpers.js'

import { md5HashGenerate } from '@/core/infrastructure/helpers/helpers.js'
// {
//  method: 'script.save_log',
//  id: '219244',                                   - script id
//  token: 'd4694663aef053c83ec5521800d60604',      - random token from script id + current time
//  ver: 31,                                        - script version
//  outcome: 'unexpected_answer',                   - log type ('unknown', 'unexpected_answer', 'forced_interruption', 'ok')
//  log: [                                          - script pass history
//    {
//      id: 's2',                                   - step id
//      cid: 403925,                                - id choosen version
//      script: {                                   - some bullshit just duplicates the id and ver of the script
//        id: 219244,
//        ver: 31
//      },
//      isgoal: 0,                                  - is it target
//      isusersort: 1                               - ?
//    },
//  ],
//  unexpected: [                                   - a batch of responses from "appropriate response is missing"
//    {
//      id: "s2",                                 - step id
//      value: "",                                - value of the response entered
//      token: "375ef022902651f046bd62fb1de73b3e" - random token consisting of script id + current time
//    },
//  ],
//  fields: [                                     - fields
//    {
//      id: 2231233,                              - field id
//      value: 'some text',                       - field value
//    },
//  ],
//  duration: 0,                                    - pass time
//  csrf_token: '27ac4d453ab2480dba644ca1d53de4a6', - I don't need it
//  user_id: 93677,                                 - user id from jwt
//  source: 'hyperscript',                          - source (hyperscript, bitrix etc.)
// }

export default {
  namespaced: true,
  state: () => ({
    passStack: {},
  }),

  /**
   * ---------------------------------------------------------------------------------------------------------------------------
   *  MUTATIONS
   * ---------------------------------------------------------------------------------------------------------------------------
   */
  mutations: {
    INIT_PASS(state, payload) {
      const { scriptId, scriptVer, userId, _uid, source } = payload
      const pass = {
        method: 'script.save_log',
        id: scriptId,
        token: md5HashGenerate(scriptId),
        ver: scriptVer,
        outcome: 'unknown',
        log: [],
        fields: [],
        unexpected: [],
        duration: 0,
        user_id: userId,
        source: source || 'go',
      }
      // Vue.set(state.passStack, _uid, pass)
      state.passStack[_uid] = pass
    },
    LOG_STEP(state, payload) {
      const { _uid, data } = payload
      state.passStack[_uid].log.push(data)
    },
    LOG_UNEXPECTED(state, payload) {
      const { _uid, data } = payload
      state.passStack[_uid].unexpected.push(data)
    },
    DONE(state, payload) {
      // console.log('🐹 DONE', payload)
      const { outcome, duration, fields, _uid } = payload
      if (outcome) {
        state.passStack[_uid].outcome = outcome
      }
      if (duration) {
        state.passStack[_uid].duration = duration
      }
      state.passStack[_uid].fields = fields
    },
    CHANGE_NO_ANSWER(state, payload) {
      const { id, value, _uid } = payload
      const find = state.passStack[_uid].unexpected.find((e) => e.id === id)
      find.value = value
    },
    BACK_TO_VIA_INDEX(state, payload) {
      const { idx, _uid, rootGetters } = payload
      const { currentWorkspace } = rootGetters.cache

      const objToArrKeys = Object.keys(state.passStack)
      const fIdx = objToArrKeys.indexOf(_uid)

      if (fIdx === objToArrKeys.length - 1) {
        state.passStack[_uid].log.splice(idx + 1)
      } else {
        let i = objToArrKeys.length - 1
        while (fIdx !== i) {
          delete state.passStack[objToArrKeys[i]]
          delete currentWorkspace[objToArrKeys[i]]
          i--
        }
        state.passStack[_uid].log.splice(idx + 1)
      }
    },
    CLEAR_PASS(state) {
      state.passStack = {}
    },
    CLEAR_FIELDS(state) {
      const keys = Object.keys(state.passStack)
      keys.forEach((key) => {
        state.passStack[key].fields = []
      })
    },
  },

  /**
   * ---------------------------------------------------------------------------------------------------------------------------
   *  ACTIONS
   * ---------------------------------------------------------------------------------------------------------------------------
   */
  actions: {
    INIT_PASS({ commit, rootGetters }) {
      const { auth, cache } = rootGetters
      commit('INIT_PASS', {
        scriptId: cache.currentScript.id,
        scriptVer: cache.currentScript.ver,
        userId: auth.userInfo.id,
        _uid: cache.currentScript._uid,
        source: cache.source,
      })
    },
    LOG({ commit, state, rootGetters }, payload) {
      const { stepId, buttonId, valueUnexpected, isGoal, isUserSort } = payload
      const { _uid } = rootGetters.cache.currentScript
      const { id, ver } = state.passStack[_uid]
      if (valueUnexpected === null) {
        commit('LOG_STEP', {
          _uid,
          data: {
            id: stepId,
            cid: buttonId,
            script: { id, ver },
            isgoal: isGoal,
            isusersort: isUserSort,
          },
        })
      } else {
        commit('LOG_UNEXPECTED', {
          _uid,
          data: {
            id: stepId,
            value: valueUnexpected,
            token: md5HashGenerate(id),
          },
        })
      }
    },
    async DONE({ commit, rootGetters }) {
      // console.log('🐹 pass/DONE')
      const { cache } = rootGetters
      const duration = timeDiffInSeconds(cache.startTime, Date.now())

      const arrValuesWorkspace = Object.values(cache.currentWorkspace)
      arrValuesWorkspace.forEach((value) => {
        const _uid = value._uid
        commit('DONE', {
          outcome: cache.currentScript._uid === _uid ? 'ok' : null,
          duration: cache.currentScript._uid === _uid ? duration : null,
          fields: cache.currentWorkspace[_uid].fields,
          _uid,
        })
      })
    },
    CHANGE_NO_ANSWER({ commit }, payload) {
      commit('CHANGE_NO_ANSWER', payload)
    },
    BACK_TO_VIA_INDEX({ commit, rootGetters }, payload) {
      commit('BACK_TO_VIA_INDEX', { ...payload, rootGetters })
    },
    CLEAR_PASS({ commit }) {
      commit('CLEAR_PASS')
    },
    CLEAR_FIELDS({ commit }) {
      commit('CLEAR_FIELDS')
    },
  },
}

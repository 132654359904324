<template>
  <div style="position: absolute; top: 0; left: 0; z-index: -1; overflow: hidden; width: 1px; height: 1px;">
    <!-- hyper-script pixel -->
    <iframe
      :src="iframeSrc"
      name="sso"
    />
    <!-- /hyper-script pixel -->
  </div>
</template>


<script>
// TODO: make npm package
import {
  authApi,
  jsonToFormData,
} from './utils.js'
import {eventBus} from '@/core/infrastructure/event-bus/event-bus.js'
const envIframeSrc = process.env.VUE_APP_SSO_PATH

export default {
  props: {
    onLoginCallback: {
      type: Function,
      // default: () => { console.log('onLoginCallback') },
      default: () => {},
    },
    onRefreshTokenCallback: {
      type: Function,
      // default: () => { console.log('onRefreshTokenCallback') },
      default: () => {},
    },
    onLogoutCallback: {
      type: Function,
      // default: () => { console.log('onLogoutCallback') },
      default: () => {},
    },
    isAuthenticatedCallback: {
      type: Function,
      // default: () => { console.log('isAuthenticatedCallback') },
      default: () => {},
    },
    getUserDataCallback: {
      type: Function,
      // default: () => { console.log('getUserDataCallback') },
      default: () => {},
    },
    onErrCallback: {
      type: Function,
      // default: () => { console.log('getUserDataCallback') },
      default: () => {},
    },
    onGiveMeCaptchaCallback: {
      type: Function,
      // default: () => { console.log('onGiveMeCaptchaCallback') },
      default: () => {},
    },
    onFbCallback: {
      type: Function,
      // default: () => { console.log('onFacebookCallback') },
      default: () => {},
    },
    onVkCallback: {
      type: Function,
      // default: () => { console.log('onVkCallback') },
      default: () => {},
    },
  },
  data: () => ({
    loggerPrefix: '[🥊 hyperscript-component-auth]',
    iframeSrc: envIframeSrc,
    savedData: null,
  }),
  created() {
    window.START_SSO = () => {
      window.sso.postMessage({
        event: 'isAuthenticated',
      }, '*')

      window.sso.postMessage({
        event: 'getUserData',
      }, '*')
    }

    window.addEventListener('message', event => {
      if (event.data.event === 'sso-loaded') {
        this.logger('sso-loaded', event.data)
        this.setSession()
      }
      if (event.data.event === 'isAuthenticated') {
        this.logger('isAuthenticated', event.data)
        this.isAuthenticatedCallback(event.data)
      }
      if (event.data.event === 'getUserData') {
        this.logger('getUserData', event.data)
        this.getUserDataCallback(event.data)
      }
      if (event.data.event === 'onTokenRefresh') {
        this.logger('refresh', event.data)
        const { status } = event.data
        if (status === 'ok') {
          this.onRefreshTokenCallback(event.data)
        } else {
          this.onLogout()
        }
      }
      if (event.data.event === 'onLogout') {
        this.logger('logout', event.data)
        this.onLogoutCallback(event.data)

        if (this.savedData !== null) {
          this.onLogin(this.savedData)
        }
      }
      if (event.data.event === 'onFacebookLogin') {
        this.logger('onFacebookLogin', event.data)
        this.onFbCallback(event.data)
      }
      if (event.data.event === 'onVkLogin') {
        this.logger('onVkLogin', event.data)
        this.onVkCallback(event.data)
      }
    })
    eventBus.on('getUserData', this.getUserData)
  },
  methods: {
    /**
     * Custom log
     */
    logger(message, payload = null, type = 'debug') {
      if (!payload) {
        console[type](this.loggerPrefix + ' ' + message)
      } else {
        console[type](this.loggerPrefix + ' ' + message, payload)
      }
    },

    /**
     * setSession() - event that is triggered after the SSO is loaded
     * @return getUserDataCallback() - call a callback from the props
     * @return isAuthenticatedCallback() - call a callback from the props
     */
    setSession() {
      // this.logger('➡️ setSession()')
      this.$nextTick(() => {
        window.sso.postMessage({
          event: 'isAuthenticated',
        }, '*')

        window.sso.postMessage({
          event: 'getUserData',
        }, '*')
      })
    },

    /**
     * getUserData()
     * @return getUserDataCallback() - call a callback from the props
     */
    getUserData() {
      window.sso.postMessage({
        event: 'getUserData',
      }, '*')
    },

    /**
     * onLogin() - form processing method
     * @param {Object} data - form fields
     * @return onLoginCallback() - call a callback from the props
     */
    async onLogin(data) {
      const req = { method: 'user.login', ...data }
      this.logger('➡️ onLogin()')
      const formData = jsonToFormData(req)
      let msg = null
      try {
        const response = await authApi.post('', formData)
        if (response.data.status === 1) {
          msg = response.data
          throw new Error()
        }
        this.savedData = null
        this.onLoginCallback()
      } catch (err) {
        this.logger('', msg, 'error')
        if (msg?.details === 'you are already authenticated') {
          this.savedData = data
          this.onLogout()
        } else if (msg?.details?.captchaNeeded === true) {
          await this.giveMeCaptcha()
          this.onErrCallback(msg)
        } else {
          this.onErrCallback(msg || err)
        }
      }
    },

    /**
     * onRefreshToken() - token refresh method
     * @return onRefreshTokenCallback() or onLogout() - call a callback from the props
     */
    onRefreshToken() {
      window.sso.postMessage({
        event: 'refreshToken',
      }, '*')
    },

    /**
     * onLogout() - log out method
     * @return onLogoutCallback() - call a callback from the props
     */
    onLogout() {
      window.sso.postMessage({
        event: 'logout',
      }, '*')
    },

    /**
     * giveMeCaptcha - generates a captcha on many unsuccessful attempts
     * @return onGiveMeCaptchaCallback(base64) - callback from props, passes base64 captcha
     */
    async giveMeCaptcha() {
      let captchaFormData = new FormData()
      captchaFormData.set('method', 'user.captcha.get')
      const response = await authApi.post('', captchaFormData)
      this.onGiveMeCaptchaCallback(response.data.response.captcha)
    },

    /**
     * onLoginViaFB() - fb login method (sso)
     * @return onFbCallback - call a callback from the props
     */
    onLoginViaFB() {
      window.sso.postMessage({
        event: 'loginFacebook',
      }, '*')
    },

    /**
     * onLoginViaVK() - vk login method (sso)
     * @return onVkCallback - call a callback from the props
     */
    onLoginViaVK() {
      window.sso.postMessage({
        event: 'loginVkontakte',
      }, '*')
    },
  },
}
</script>

import axios from 'axios'
// eslint-disable-next-line
import { eventBus, interceptorCallback } from '../infrastructure/event-bus/event-bus.js'

if (process.env.NODE_ENV === 'development') {
  console.log('🚑 ENV/VUE_APP_PASS_API', process.env.VUE_APP_PASS_API)
}

let refresh_opposition = 0

const api = axios.create({
  baseURL: process.env.VUE_APP_PASS_API,
  withCredentials: true, // enabled hs cookies
  headers: {
    Accept: 'application/json',
  },
})

api.interceptors.response.use(
  (response) => {
    // Return a successful response back to the calling service
    return response
  },
  (error) => {
    window.showErrorInAxiosInterceptor = error

    if (error.message === 'Network Error') {
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        interceptorCallback({ callback: 'offline' })
      })
    }

    // eslint-disable-next-line
    return new Promise((resolve, reject) => {
      console.error('🐹 api.interceptors.response.use', error, error.response.status)
      if (error.response.status === 401) {
        refresh_opposition += 1
        if (refresh_opposition > 1) {
          refresh_opposition = 0
          interceptorCallback({ callback: 'logout' })
        } else {
          interceptorCallback({ callback: 'refreshToken' })
        }
      }
      else if (error.response.status === 402) {
        interceptorCallback({ callback: 'paymentRequired' })
      }
      else if (error.response.status === 403 || error.response.status === 404) {
        interceptorCallback({ callback: 'exitInScript' })
      } else {
        interceptorCallback({ callback: 'logout' })
      }
    })
  },
)

export default api

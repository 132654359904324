<template>
  <button
    class="flex justify-center items-center text-white focus:outline-none rounded-xl focus:ring-2 focus:ring-opacity-50 bg-hs-social transition-colors"
    :class="theme"
    :disabled="disabled"
    :type="type"
    style="width: 50px; height: 50px;"
  >
    <slot />
  </button>
</template>


<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    variant: {
      type: String,
      required: true,
      validator: value => [
        'fb',
        'vk',
        'ok',
      ].indexOf(value) !== -1,
    },
  },
  data: () => ({
    themes: {
      fb: 'hover:bg-hs-s-fb focus:ring-hs-s-fb focus:bg-hs-s-fb',
      vk: 'hover:bg-hs-s-vk focus:ring-hs-s-vk focus:bg-hs-s-vk',
      ok: 'hover:bg-hs-s-ok focus:ring-hs-s-ok focus:bg-hs-s-ok',
    },
  }),
  computed: {
    theme() { return this.themes[this.variant] },
  },
}
</script>
